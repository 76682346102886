import { AnyAction } from 'redux';

// custom action creator
import { actionCreator } from './actionCreator/actionCreator';

// action types
import { types } from './actionTypes/actionTypes';

// helpers
import api from "../helpers/services/api";
import { dateFilterToDays } from '../helpers/dateFilterToDays';
import { extendContentInformation } from "../helpers/generic/extendContent";
import { filterByCreationDate } from '../helpers/generic/filterByCreationDate';

// interfaces
import { Video } from '../interfaces/video/Video.interface';


/**
 * Get all available videos from the database for the current user
 */
export const loadVideoFiles = async (dateFilter: string): Promise<AnyAction> => {
    try {
        const resp = await api.video.getAll(dateFilter);

        if (resp.status === 200) {
            const videoFiles: Video[] = await resp.json();

            let payload = videoFiles.map(file => extendContentInformation(file)) as Video[];
            if (dateFilter !== 'all') {
                payload = filterByCreationDate(payload, dateFilterToDays(dateFilter)) as Video[];
            }

            return actionCreator(types.videoFilesRead, payload);

        } else {
            const errorMsg = 'Error in the loadVideoFiles action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the loadVideoFiles action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export const cleanVideoFilesList = (): AnyAction => actionCreator(types.cleanFilesList);


/**
 * Update a video in the list according to the received notification
 */
export const updateVideoInFiles = async (videoId: number, videoList: Video[]): Promise<AnyAction> => {
    try {
        const resp = await api.video.getVideoInfo(videoId);

        if (resp.status === 200) {
            const updatedVideo: Video = await resp.json();
            const updatedVideoExtended = extendContentInformation(updatedVideo) as Video;

            // Remove the old instance of the article if it already exists, then add the new instance
            const videoListToUpdate: Video[] = videoList.filter(video => video.id !== videoId);
            videoListToUpdate.push(updatedVideoExtended);

            return actionCreator(types.videoFileUpdate, videoListToUpdate);
        } else {
            const errorMsg = 'Error in the UpdateVideo action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the UpdateVideo action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

/**
 * Filter the visible files.
 * @param query {string} - The query to filter the articles.
 * @param files {FileRow[]} - The files to filter.
 * @return {(function(*): Promise<void>)|*}
 */
export const filterFiles = (query: string, files: Video[]): AnyAction => {
    try {
        const resetFiles = files.map(file => ({ ...file, visible: false }));
        const filteredFiles = resetFiles.map(file => {
            if (file.fileName.toLowerCase().includes(query.toLowerCase())) {
                return { ...file, visible: true };
            }
            return file;
        })

        return actionCreator(types.videoFilesFilter, filteredFiles);

    } catch (error) {
        console.error(error);
        throw new Error('Errors in filterFiles action');
    }
}

/**
 * Sort the articles depending on different ordenation modes
 */
export const sortFiles = (orderedMode: string, files: Video[]): AnyAction => {
    try {
        switch (orderedMode) {
            case "createdup":
                files.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                break;

            case "createddown":
                files.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
                break;

            case "updatedup":
                files.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)
                break;

            case "updateddown":
                files.sort((a, b) => a.updatedAt < b.updatedAt ? -1 : 1)
                break;

            case "nameaz":
                const nameB = [];
                for (let file of files) {
                    nameB.push({ pub: file.fileName, id: file.id })
                    file.fileName = file.fileName.toLowerCase()
                }
                files.sort(
                    (a, b) => a.fileName.replace(/[^a-z]/ig, '') > b.fileName.replace(/[^a-z]/ig, '') ? 1 : -1
                );
                break;

            case "nameza":
                files.sort();
                files.reverse();
                break;

            default:
                break;
        }

        return actionCreator(types.videoFilesSort, [...files]);

    } catch (error) {
        console.error(error);
        throw new Error('Errors in sortFiles action')
    }
}

/**
 * Apply search filter to videos
 */
export const searchFiles = (files: Video[]): AnyAction => {
    return actionCreator(types.videoFilesRead, files);
}


/**
 * Update videos
 */
export const updateFiles = (files: Video[]): AnyAction => {
    return actionCreator(types.videoFilesUpdate, files);
}
