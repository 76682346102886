import { createTheme } from "@mui/material/styles";

export const lightTheme = () => {
    return createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#1976d2',
                light: '#e6fbfc',
                dark: '#3f51b4',
            },
            secondary: {
                main: '#ff0033',
                light: '#f5f5f5',
                dark: '#7e7e7e',
            },
            info: {
                main: '#e8e8e8',
                light: '#f8f9fb'
            },
            warning: {
                main: '#ff9800',
            }
        }
    });
}
