import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// components
import ImageUploading, { ImageListType } from 'react-images-uploading';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { VideoUpdateInfoBody } from '../../../../../interfaces/services/api.interfaces';

// actions
import { updateVideoInfo, updateVideoOutroInfoImage, deleteVideoOutroInfoImage } from '../../../../../actions/video.actions';

export const OutroBox = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const video = useSelector((state: Selector) => state.video);
    const [name, setName] = useState(video.outroInfoName ?? '');
    const [email, setEmail] = useState(video.outroInfoEmail ?? '');
    const [phone, setPhone] = useState(video.outroInfoPhone ?? '');
    const [pictures, setPictures] = useState<any>([]);

    const prevVideoId = useRef(video.id);

    useEffect(() => {
        if (prevVideoId.current !== video.id) {
            setName(video.outroInfoName ?? '');
            setEmail(video.outroInfoEmail ?? '');
            setPhone(video.outroInfoPhone ?? '');
            const images = video.outroImageUrl?.length > 4 ? [{ data_url: video.outroImageUrl }] : [];
            setPictures(images);
            prevVideoId.current = video.id;
        }
    }, [video]);

    const videoSaveBody: VideoUpdateInfoBody = {
        id: video.id,
        outroInfoEmail: video.outroInfoEmail ?? '',
        outroInfoName: video.outroInfoName ?? '',
        outroInfoPhone: video.outroInfoPhone ?? '',
    };

    const onImageUploading = (imageList: ImageListType) => {
        setPictures(imageList);

        if (imageList.length > 0 && imageList[0].data_url) {
            updateVideoOutroInfoImage(video.id, imageList[0].data_url).then(action => dispatch(action));
        }
    };

    const handleImageDelete = () => {
        setPictures([]);

        deleteVideoOutroInfoImage(video.id).then(action => dispatch(action));
    };

    const handleUpdateName = (e: any) => {
        setName(e.target.value);
    };

    const handleUpdatePhone = (e: any) => {
        setPhone(e.target.value);
    };

    const handleUpdateEmail = (e: any) => {
        setEmail(e.target.value);
    };

    // update video on DB when the any text field is blurred
    const handleUpdateOutroInfo = () => {
        updateVideoInfo({
            ...videoSaveBody,
            outroInfoName: name,
            outroInfoEmail: email,
            outroInfoPhone: phone,
        }).then(action => dispatch(action));
    };

    return (
        <Box width="100%">
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 4,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    p: 3,
                }}
            >
                <Typography variant="h6" color={'primary'}>
                    Outro Info
                </Typography>

                <Stack direction="row" sx={{ my: 3 }}>
                    <Stack direction={'column'} spacing={2} sx={{ width: '50%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <AccountCircleIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField
                                data-test="video-input-outro-name"
                                id="video-input-outro-name"
                                name="video-input-outro-name"
                                label="Name"
                                variant="standard"
                                value={name}
                                autoComplete="off"
                                onChange={handleUpdateName}
                                onBlur={handleUpdateOutroInfo}
                                sx={{ width: '75%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <LocalPhoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField
                                data-test="video-input-outro-phone"
                                id="video-input-outro-phone"
                                name="video-input-outro-phone"
                                label="Phone"
                                variant="standard"
                                value={phone}
                                autoComplete="off"
                                onChange={handleUpdatePhone}
                                onBlur={handleUpdateOutroInfo}
                                sx={{ width: '75%' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <EmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField
                                data-test="video-input-outro-email"
                                id="video-input-outro-email"
                                name="video-input-outro-email"
                                label="Email"
                                variant="standard"
                                value={email}
                                autoComplete="off"
                                onChange={handleUpdateEmail}
                                onBlur={handleUpdateOutroInfo}
                                sx={{ width: '75%' }}
                            />
                        </Box>
                    </Stack>
                    <Stack direction={'column'} spacing={2} sx={{ width: '50%' }}>
                        <ImageUploading
                            value={pictures}
                            onChange={onImageUploading}
                            maxNumber={1}
                            dataURLKey="data_url"
                        >
                            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, isDragging, dragProps }) => (
                                <Box sx={{ p: 1 }}>
                                    {imageList.length === 0 ? (
                                        <Button
                                            variant="outlined"
                                            onClick={onImageUpload}
                                            disableElevation
                                            color={isDragging ? 'secondary' : 'primary'}
                                            {...dragProps}
                                            sx={{
                                                minWidth: '230px',
                                                minHeight: '230px',
                                                cursor: 'pointer',
                                                border: '3px solid',
                                                borderRadius: 3,
                                                borderColor: '#DAE2ED',
                                                borderStyle: 'dashed',
                                            }}
                                        >
                                            <AddCircleIcon />
                                            &nbsp;
                                            <Typography>Drop a photo</Typography>
                                        </Button>
                                    ) : (
                                        <Box>
                                            <IconButton
                                                aria-label="set image"
                                                color="primary"
                                                onClick={() => onImageUpdate(0)}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>

                                            <IconButton
                                                aria-label="delete image"
                                                color="secondary"
                                                onClick={() => {
                                                    onImageRemoveAll();
                                                    setPictures([]);
                                                    handleImageDelete();
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    )}
                                    <Grid container>
                                        {imageList.map((image, index) => (
                                            <Box key={index} className="image-item" sx={{ p: 1.6 }}>
                                                <img src={image['data_url']} alt={image.dataURL} width="148" />
                                            </Box>
                                        ))}
                                    </Grid>
                                </Box>
                            )}
                        </ImageUploading>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
};
