import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import { AudioEditorPage } from '../../audioEditorPage/AudioEditorPage';
import { PlaylistPage } from '../../playlistPage/PlaylistPage';
import { ThesaurusDetectionPage } from '../../thesaurusDetectionPage/ThesaurusDetectionPage';
import { VideoEditorPage } from '../../videoEditorPage/VideoEditorPage';

// mui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Selector } from '../../../../../interfaces/Selector.interface';

export const ContentPage = () => {
    const theme = useTheme();

    // status selector
    const role = useSelector((state: Selector) => state.auth.role);
    const selectedTabName = useSelector((state: Selector) => state.sidebar.selectedTabName);

    const [selectedTab, setSelectedTab] = useState('Audio');

    useEffect(() => {
        setSelectedTab(selectedTabName);
    }, [selectedTabName, setSelectedTab]);

    return (
        <Box sx={{ marginTop: theme.spacing(6) }}>
            {selectedTab === 'audio' && <AudioEditorPage />}
            {selectedTab === 'video' && <VideoEditorPage />}
            {role === 'SuperAdmin' && selectedTab === 'lexicon' && <ThesaurusDetectionPage />}
            {role === 'SuperAdmin' && selectedTab === 'playlist' && <PlaylistPage />}
        </Box>
    );
};
