import { Article } from "../../interfaces/article/Article.interface";
import { Video } from "../../interfaces/video/Video.interface";

export const filterByCreationDate = (files: Article[] | Video[], dateToFilter: Date): Article[] | Video[] => {
    const filteredFiles = files.map(file => {
        if (!file.createdAt) return { ...file, visible: false };

        const creationDate = new Date(file.createdAt);
        creationDate.setHours(0, 0, 0, 0);

        if (creationDate >= dateToFilter) {
            return { ...file, visible: true };
        } else {
            return { ...file, visible: false };
        }
    });
    return filteredFiles as Article[] | Video[];
};
