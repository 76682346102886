/**
 * Extend articles with additional properties for front usage.
 */
import { Article } from '../../interfaces/article/Article.interface';
import { ArticlePart } from "../../interfaces/article/ArticlePart.interface";
import { Video } from '../../interfaces/video/Video.interface';
import { VideoPart } from '../../interfaces/video/VideoPart.interface';
import { dtoToHtmlPart } from './processContent';


export const extendContentInformation = (content: Article | Video): Article | Video => {
    return {
        ...content,
        visible: true,
        isSelected: false,
    }
}

export const extendContentPart = (contentPart: ArticlePart | VideoPart): ArticlePart | VideoPart => {
    return {
        ...contentPart,
        contentHtml: dtoToHtmlPart(contentPart),
        contentHtmlOriginal: dtoToHtmlPart(contentPart)
    }
}
