import React, { useEffect, useMemo, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Flag from 'react-world-flags';

// mui
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';

// styles
import '../../../audioEditorPage/styles/country-flags.css';

// data
import { getFlagCode, getLabel } from '../../../../../../data/languageLabels';

// actions
import { updateVoiceList } from '../../../../../../actions/voices.actions';
import { updateVideoLanguage } from '../../../../../../actions/video.actions';

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';

export const AudioScriptLanguageSelector = React.memo(() => {
    const dispatch = useDispatch();

    // state selector
    const languageList = useSelector((state: Selector) => state.languages.languageList);
    const video = useSelector((state: Selector) => state.video);
    const voiceList = useSelector((state: Selector) => state.voices.voiceList);

    const enabledVoices = useMemo(() => voiceList.filter(v => v.enabled), [voiceList]);
    const languages: string[] = useMemo(() => Array.from(new Set(enabledVoices.map(v => v.language))), [enabledVoices]);

    const [currentStateLanguage, setCurrentStateLanguage] = useState('');

    // effect to apply selected language to voice list
    useEffect(() => {
        if (currentStateLanguage !== video.language?.code) {
            setCurrentStateLanguage(video.language?.code);
        }
        // eslint-disable-next-line
    }, [video.language?.code]);

    useEffect(() => {
        const languageVoices = voiceList.map(v => ({ ...v, visible: v.language === currentStateLanguage }));
        dispatch(updateVoiceList(languageVoices));
        // eslint-disable-next-line
    }, [currentStateLanguage]);

    const handleLanguageChange = (e: SelectChangeEvent<string>) => {
        const languageCode = e.target.value;
        if (currentStateLanguage !== languageCode) {
            setCurrentStateLanguage(languageCode);

            updateVideoLanguage(video.id, languageCode)
                .then(action => {
                    dispatch(action);
                    return 'ok';
                })
                .catch(e => {
                    return e;
                });
        }
    };

    return (
        <Box sx={{ textAlign: 'left', width: '40%' }}>
            <FormControl fullWidth>
                <InputLabel id="languageSelectorLabel">Language</InputLabel>
                <Select
                    labelId="languageSelectorLabel"
                    id="languageSelector"
                    data-test="language-selector"
                    value={languages.includes(currentStateLanguage) ? currentStateLanguage : ''}
                    label="Language"
                    onChange={handleLanguageChange}
                    fullWidth
                >
                    {languages.sort().map(lang => {
                        return (
                            <MenuItem data-test={`languageselector-${lang}`} key={lang} value={lang}>
                                <Stack direction="row" spacing={1}>
                                    <Flag id="flag-img-language" code={getFlagCode(lang)} />
                                    <Typography component="div">{getLabel(languageList, lang)}</Typography>
                                </Stack>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
});
