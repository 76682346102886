import { VideoUpdateBody } from "../../interfaces/services/api.interfaces";
import { VideoWithContent } from "../../interfaces/video/Video.interface";
import { VideoKeyword } from "../../interfaces/video/VideoKeyword.interface";

export const generateVideoSaveBody = (video: VideoWithContent): VideoUpdateBody => {
    const videoUpdateBody: VideoUpdateBody = {
        id: video.id,
        name: video.name,
        languageCode: video.language.code,
        outroImageUrl: video.outroImageUrl,
        outroInfoEmail: video.outroInfoEmail,
        outroInfoName: video.outroInfoName,
        outroInfoPhone: video.outroInfoPhone,
        introMusicEffect: video.introMusicEffect,
        backgroundMusicEffect: video.backgroundMusicEffect,
        parts: video.parts,
        images: video.images,
        keywords: cleanKeywords(video.keywords),
    };
    return videoUpdateBody;
}

const cleanKeywords = (keywords: VideoKeyword[]): VideoKeyword[] => {
    const cleanKeywords: VideoKeyword[] = [];
    for (let keyword of keywords) {
        if (keyword.text.length > 0) {
            cleanKeywords.push({
                id: keyword.id,
                group: keyword.group,
                line: keyword.line,
                text: keyword.text,
            });
        }
    }
    return cleanKeywords;
}