// mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

// helpers
import { formatDateToString } from '../../../../../../../helpers/formatDateToString';

interface Props {
    title: string;
    creationDate: number;
}

export const ItemTitle = ({ title, creationDate }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    return (
        <Box>
            <Stack direction="column">
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center', display: 'flex' }}>
                    <Typography variant="inherit">{title}</Typography>
                </Stack>

                <Typography variant="caption" sx={{ color: palette.secondary.dark }}>
                    {formatDateToString(creationDate)}
                </Typography>
            </Stack>
        </Box>
    );
};
