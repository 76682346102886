import { VideoKeyword } from "../../interfaces/video/VideoKeyword.interface";

export const fillMissingKeywordGroupsLines = (data: VideoKeyword[]): VideoKeyword[] => {
    const groups: Record<number, VideoKeyword[]> = {};

    // Organize data by group
    data.forEach(item => {
        if (!groups[item.group]) {
            groups[item.group] = [];
        }
        groups[item.group].push(item);
    });

    // Ensure each group has exactly 3 lines
    const result: VideoKeyword[] = [];
    Object.entries(groups).forEach(([group, items]) => {
        const existingLines = new Set(items.map(item => item.line));
        for (let line = 1; line <= 3; line++) {
            if (!existingLines.has(line)) {
                items.push({ id: 0, group: Number(group), line, text: '' });
            }
        }
        // Sort lines to maintain order
        result.push(...items.sort((a, b) => a.line - b.line));
    });

    return result;
};