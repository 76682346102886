import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AudioActionButton } from '../AudioActionButton';
import {
    setActiveArticleAudioGenerationStatus,
    setActiveArticleStatus,
} from '../../../../../../../actions/articles.actions';
import { Selector } from '../../../../../../../interfaces/Selector.interface';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { getFileName } from '../../../../../../../helpers/articles/audioActionButtons/audioActionButtonsUtils';
import {
    showInformationSnackBar,
    showPermanentErrorSnackBar,
    showPermanentSuccessSnackBar,
} from '../../../../../../../actions/snackBar.actions';
import { newArticleAudioGenerated } from '../../../../../../../actions/socket.actions';
import api from '../../../../../../../helpers/services/api';
import { saveArticle } from '../../../../../../../helpers/articles/saveArticle';
import { Article } from '../../../../../../../interfaces/article/Article.interface';

interface Props {
    isSaving: boolean;
    setIsSaving: (isSaving: boolean) => void;
    isGenerating: boolean;
    setIsGenerating: (isGenerating: boolean) => void;
}

export const AudioActionButtonGenerate = ({ isSaving, setIsSaving, setIsGenerating, isGenerating }: Props) => {
    const dispatch = useDispatch();

    const article = useSelector((state: Selector) => state.article);
    const userData = useSelector((state: Selector) => state.auth);
    const articleParts = useSelector((state: Selector) => state.parts);
    const files = useSelector((state: Selector) => state.files);
    const socketAudioGenerated = useSelector((state: Selector) => state.socket.newArticleAudioGeneration);

    const { activeArticle, editor } = article;

    // trigger audio generation
    const handleGenerateAudio = () => {
        setIsGenerating(true);

        // reset loaded audio
        dispatch(setActiveArticleAudioGenerationStatus('GENERATING'));

        // getFileName is a promise, so it is treated like one.
        getFileName(activeArticle)
            .then(fileName => {
                if (fileName.length === 0) {
                    dispatch(setActiveArticleAudioGenerationStatus('GENERATION_ERROR'));
                    return;
                }
                let fileId = -1;
                if (activeArticle.id) fileId = activeArticle.id;

                const articleData = {
                    fileName,
                    fileId,
                    articleParts: [...articleParts],
                    activeArticle,
                };

                // save article
                saveArticle(userData, articleData, true)
                    .then(async (resp: any) => {
                        dispatch(showInformationSnackBar('Generating audio...'));

                        if (fileId <= 0) {
                            const respBody = await resp.json();
                            fileId = respBody?.id;
                        }

                        // start audio generation: socket will handle the server response
                        api.audio.generateArticle(fileId).then(async (resp: any) => {
                            const respBody = await resp.json();
                            if (resp.ok !== true) {
                                handleGenerationError(respBody.error);
                            }
                        });
                    })
                    .catch((err: any) => {
                        handleGenerationError(err);
                    });
            })
            .catch(err => {
                handleGenerationError(err);
            });
    };

    const handleGenerationError = (err: any) => {
        setIsGenerating(false);
        dispatch(setActiveArticleAudioGenerationStatus('GENERATION_ERROR'));
        if (err !== undefined && err !== '') {
            dispatch(showPermanentErrorSnackBar(err));
        } else {
            dispatch(showPermanentErrorSnackBar(`Error during audio generation`));
        }

        console.error(err);
    };

    // effect to handle the state when activeArticle changed
    useEffect(() => {
        setIsSaving(false);
        if (activeArticle.audioMediaFile?.generationStatus === 'GENERATING') {
            setIsGenerating(true);
        } else {
            setIsGenerating(false);
        }
    }, [activeArticle.id, activeArticle.audioMediaFile?.generationStatus, dispatch, setIsSaving, setIsGenerating]);

    // effect to detect when the active article has finished the generation
    useEffect(() => {
        if (isGenerating) {
            const currentArticleIsGenerated = files.filter(
                (file: Article) =>
                    file.id === activeArticle.id && file.audioMediaFile?.generationStatus !== 'GENERATING',
            );
            if (currentArticleIsGenerated.length > 0) setIsGenerating(false);
        }
        // eslint-disable-next-line
    }, [files]);

    // effect to handle socket audio generation notification
    useEffect(() => {
        if (socketAudioGenerated > 0 && socketAudioGenerated === activeArticle.id) {
            dispatch(showPermanentSuccessSnackBar('Audio generated successfully'));
            dispatch(setActiveArticleStatus('DRAFT'));
            setIsGenerating(false);

            // reset socket audio generation flag
            dispatch(newArticleAudioGenerated(-1));
        }

        // eslint-disable-next-line
    }, [socketAudioGenerated]);

    return (
        <AudioActionButton
            data_test="audioactionbuttons-btn-generate"
            title={'Generate the audio MP3'}
            icon={<AudioFileIcon fontSize="small" />}
            onClick={handleGenerateAudio}
            disabled={editor.hasSyntaxError || editor.hasPartWithSymbolsOnly || isSaving || isGenerating}
            isLoading={isGenerating}
            isLoadingButton={true}
            name={isGenerating ? 'Generating...' : 'Generate'}
        />
    );
};
