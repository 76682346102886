import React, { useState, MouseEvent, useEffect } from 'react';

// modules
import { useDispatch } from 'react-redux';

// mui
import { Box, Checkbox, Stack } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';

// components
import { ItemTitle } from './itemTitle/ItemTitle';
import { SideBarVideoItemContextMenu } from '../contextMenu/SideBarItemContextMenu';
import ContextMenu from '../../../../../generics/ContextMenu';

// interfaces
import { Video, VideoWithContent } from '../../../../../../interfaces/video/Video.interface';

// actions
import { resetAudioState } from '../../../../../../actions/audio.actions';
import { showWarningSnackBar } from '../../../../../../actions/snackBar.actions';
import { clearActiveVideo, setActiveVideo, setVideoIsDownloadable } from '../../../../../../actions/video.actions';

// helpers
import api from '../../../../../../helpers/services/api';
import { dtoToHtmlPart } from '../../../../../../helpers/generic/processContent';

interface Props {
    videoItem: Video;
    deleteMode: boolean;
    selectedFiles: number[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<number[]>>;
}

export const VideoFileItem = ({ videoItem, deleteMode, selectedFiles, setSelectedFiles }: Props) => {
    const dispatch = useDispatch();

    const [contextMenu, setContextMenu] = useState(null) as any;
    const [isSelected, setIsSelected] = useState(false);

    const { id, createdAt, fileName } = videoItem;

    const handleItemSelection = (item: number) => {
        if (selectedFiles.includes(item)) {
            setSelectedFiles(selectedFiles.filter(n => n !== item));
        } else {
            setSelectedFiles([...selectedFiles, item]);
        }
    };

    const handleOpenContextMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null,
        );
    };

    const handleCloseContextMenu = () => setContextMenu(null);

    /**
     * Manage the selection of an video and set it active.
     * @param video {Video} - The video to load
     */
    const handleSelectFile = async (video: Video): Promise<void> => {
        if (!video) return;

        const resp = await api.video.get(video.id);
        const videoWithContent: VideoWithContent = await resp.json();

        const extendedParts = videoWithContent.parts.map(part => ({
            ...part,
            contentHtml: dtoToHtmlPart(part),
            contentHtmlOriginal: dtoToHtmlPart(part),
        }));

        videoWithContent.parts = extendedParts;

        if (videoWithContent) {
            dispatch(setActiveVideo(videoWithContent));
        } else {
            dispatch(clearActiveVideo());
            dispatch(showWarningSnackBar('Video could not be loaded'));
        }

        const { status, videoMediaFile } = videoWithContent;

        // length > 4 to ensure it's longer than a file extersion like '.mp3'
        if ((status === 'DRAFT' || status === 'PUBLISHED') && videoMediaFile?.url && videoMediaFile?.url?.length > 4) {
            dispatch(setVideoIsDownloadable(true));
        } else {
            dispatch(setVideoIsDownloadable(false));
            dispatch(resetAudioState());
        }
    };

    useEffect(() => {
        const isVersionSelected = videoItem.isSelected;
        setIsSelected(isVersionSelected);
        // eslint-disable-next-line
    }, [videoItem.isSelected]);

    return (
        <Stack
            data-test={`audiofile-item-${id}`}
            direction="row"
            onContextMenu={handleOpenContextMenu}
            sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
        >
            {deleteMode && (
                <Box className="delete-mode">
                    <Checkbox
                        key={id}
                        color="primary"
                        sx={{ p: 0, mr: '5px' }}
                        checked={isSelected}
                        onChange={() => handleItemSelection(id)}
                    />
                </Box>
            )}

            <TreeItem
                id={`${id}`}
                key={`video-file-${id}`}
                label={<ItemTitle title={fileName} creationDate={createdAt} />}
                nodeId={`${id}`}
                onClick={() => videoItem.status !== 'CREATING' && handleSelectFile(videoItem)}
                sx={{
                    width: '100%',
                    borderRadius: 10,
                    '& .MuiSelect-select:hover': {
                        background: 'red',
                    },
                }}
                disabled={videoItem.status === 'CREATING'}
            />
            <ContextMenu.ContextMenu
                open={contextMenu !== null}
                handleClose={handleCloseContextMenu}
                anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
                data={() => SideBarVideoItemContextMenu({ item: videoItem })}
            />
        </Stack>
    );
};
