import { useState, useEffect, useRef } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// components
import { Player } from '../../../../../../generics/player/Player';

// mui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    Typography,
} from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

// actions
import { articlesEditorChangeTitleMusic, setActiveArticle } from '../../../../../../../actions/articles.actions';

// default state
import { playerDefaultState } from '../../../../../../../state/playerDefaultState';

// interfaces
import { Selector } from '../../../../../../../interfaces/Selector.interface';
import { JingleEffect } from '../../../../../../../interfaces/generic/JingleEffect.interface';

// handlers
import { handleLoadAudio, handlePlayPause, handleVolumeChange } from '../../../../../../generics/player/playerHandlers';

const StyledInput = styled(Input)`
    width: 50px;
`;

export default function TitleMusicSelector() {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);
    const jingleList = useSelector((state: Selector) => state.soundEffects.jingleList);

    const [selectedJingleId, setSelectedJingleId] = useState(0);
    const [playerState, setPlayerState] = useState({ ...playerDefaultState });
    const playerRef = useRef(null);

    const defaultVolume: number = 1;

    const jingleVolumeParams = {
        step: 0.05,
        min: 0,
        max: 1,
        defaultVolume: activeArticle?.titleMusicEffect?.volume || defaultVolume,
    };

    const [jingleVolume, setJingleVolume] = useState(jingleVolumeParams.defaultVolume);

    const updateArticleTitleMusic = (titleMusicEffect: JingleEffect) => {
        dispatch(
            setActiveArticle({
                ...activeArticle,
                titleMusicEffect,
            }),
        );
    };

    const handleJingleChange = (e: any) => {
        if (e.target.value === '' || e.target.value === 0) {
            setSelectedJingleId(e.target.value);
            setJingleVolume(defaultVolume);

            const titleMusicEffect: JingleEffect = {
                id: 0,
                defaultVolume: defaultVolume,
                volume: defaultVolume,
                url: '',
            };
            updateArticleTitleMusic(titleMusicEffect);
            handleLoadAudio(setPlayerState, '', false, jingleVolume);

            // update state
            dispatch(articlesEditorChangeTitleMusic(e.target.value));
        } else {
            const selectedJingle = jingleList.filter((j: { id: any }) => j.id === e.target.value)[0];

            setJingleVolume(selectedJingle?.defaultVolume ? +selectedJingle?.defaultVolume : defaultVolume);
            setSelectedJingleId(e.target.value);

            const selectedTitleMusicEffect: JingleEffect = {
                id: selectedJingle.id,
                defaultVolume: selectedJingle.defaultVolume,
                volume: selectedJingle.defaultVolume,
                url: selectedJingle.url,
            };
            updateArticleTitleMusic(selectedTitleMusicEffect);
            handleLoadAudio(setPlayerState, selectedJingle?.url || '', false, jingleVolume);

            // update state
            dispatch(articlesEditorChangeTitleMusic(e.target.value));
        }
    };

    const handleSliderChange = (e: any, newValue: any) => {
        setJingleVolume(+newValue);
    };

    const handleSliderChangeCommitted = (e: any, newValue: any) => {
        updateJingleVolume(+newValue);
    };

    const handleInputChange = (e: any) => {
        updateJingleVolume(+e.target.value);
    };

    const updateJingleVolume = (volume: number) => {
        let selectedJingle = jingleList.filter(j => j.id === selectedJingleId)[0];
        if (!selectedJingle) {
            const titleMusicEffect: JingleEffect = {
                id: 0,
                defaultVolume,
                volume: jingleVolume,
                url: '',
            };
            updateArticleTitleMusic(titleMusicEffect);
            dispatch(articlesEditorChangeTitleMusic(titleMusicEffect.id));
        } else {
            setJingleVolume(volume);
            handleVolumeChange(setPlayerState, volume);

            const selectedTitleMusicEffect: JingleEffect = {
                id: selectedJingle.id,
                defaultVolume: selectedJingle.defaultVolume,
                volume,
                url: selectedJingle.url,
            };
            updateArticleTitleMusic(selectedTitleMusicEffect);
        }
    };

    const handleInputBlur = () => {
        if (jingleVolume < 0) {
            setJingleVolume(0);
            handleVolumeChange(setPlayerState, 0);
        } else if (jingleVolume > 1) {
            setJingleVolume(1);
            handleVolumeChange(setPlayerState, 1);
        }
    };

    useEffect(() => {
        const jingleId = activeArticle?.titleMusicEffect?.id || 0;
        const jingleVolume = activeArticle?.titleMusicEffect?.volume || defaultVolume;

        setSelectedJingleId(jingleId);
        setJingleVolume(jingleVolume);

        let selectedJingle = jingleList.filter(j => j.id === jingleId)[0];
        handleLoadAudio(setPlayerState, selectedJingle?.url || '', false, jingleVolume);

        // eslint-disable-next-line
    }, [activeArticle.id, jingleList]);

    return (
        <Box sx={{ textAlign: 'left', mb: 5 }}>
            <Player playerState={playerState} setPlayerState={setPlayerState} playerRef={playerRef} />

            <Stack direction="row">
                <FormControl fullWidth>
                    <InputLabel id="titleMusicSelectorLabel">Title Music</InputLabel>
                    <Select
                        id="titleMusicSelector"
                        key="titleMusicSelector"
                        data-test="title-music-selector"
                        label="Title Music"
                        labelId="titleMusicSelectorLabel"
                        onChange={handleJingleChange}
                        disabled={playerState.playing}
                        value={jingleList.filter(b => b.id === selectedJingleId).length > 0 ? selectedJingleId : ''}
                    >
                        <MenuItem value={0} key={''}>
                            <em>None</em>
                        </MenuItem>
                        {jingleList &&
                            jingleList.length > 0 &&
                            jingleList.map(jingle => (
                                <MenuItem value={jingle.id} key={jingle.name}>
                                    {jingle.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <IconButton
                    aria-label="startPause"
                    onClick={() => handlePlayPause(setPlayerState)}
                    color="primary"
                    disabled={
                        (!playerState.playing && playerState.url === '') ||
                        (playerState.playing && playerState.isBuffering)
                    }
                    sx={{ fontSize: 32, marginTop: '5px' }}
                >
                    {playerState.url === '' &&
                    playerState.playing &&
                    (!playerState.duration || playerState.isBuffering) ? (
                        <CircularProgress color="primary" size={32} />
                    ) : playerState.playing ? (
                        <PauseCircleIcon fontSize="inherit" />
                    ) : (
                        <PlayCircleFilledWhiteIcon fontSize="inherit" />
                    )}
                </IconButton>
            </Stack>

            <Box sx={{ width: '100%', mt: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography
                            id="input-slider"
                            component="div"
                            gutterBottom
                            color={theme.palette.text.secondary}
                            fontSize={'small'}
                            sx={{ textAlign: 'left' }}
                        >
                            Volume
                        </Typography>
                    </Grid>
                    <Grid item xs mr={1.5}>
                        <Slider
                            aria-label="jingleVolume"
                            aria-labelledby="input-slider"
                            value={jingleVolume}
                            onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommitted}
                            size="small"
                            step={jingleVolumeParams.step}
                            min={jingleVolumeParams.min}
                            max={jingleVolumeParams.max}
                            disabled={selectedJingleId === 0}
                        />
                    </Grid>
                    <Grid item>
                        <StyledInput
                            value={jingleVolume}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            inputProps={{
                                step: jingleVolumeParams.step,
                                min: jingleVolumeParams.min,
                                max: jingleVolumeParams.max,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            disabled={selectedJingleId === 0}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
