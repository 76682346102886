import { VideoPlayer } from './VideoPlayer';

// mui
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { useEffect, useState } from 'react';
import { MediaFile } from '../../../../../interfaces/article/MediaFile.interface';

export const VideoPlayerBox = () => {
    const theme = useTheme();

    const isGeneratingVideo = useSelector((state: Selector) => state.video.isGeneratingVideo);
    const videoMediaFile = useSelector((state: Selector) => state.video.videoMediaFile as MediaFile);
    const [videoSrc, setVideoSrc] = useState(videoMediaFile?.url ?? '');

    // effect to load video on url source change
    useEffect(() => {
        if (videoMediaFile?.url && videoMediaFile.url !== videoSrc) {
            setVideoSrc(videoMediaFile.url);
            const video = document.getElementById('video-player') as HTMLVideoElement;
            const source = document.getElementById('video-src');
            video?.pause();
            source?.setAttribute('src', videoMediaFile.url);
            video?.load();
        }
    }, [videoMediaFile?.url, videoSrc]);

    return (
        <Box sx={{ pt: 1 }}>
            <Box
                width={'100%'}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 4,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    height: 'auto',
                    padding: 3,
                }}
            >
                <Typography variant="h6" color="primary" sx={{ mb: 3 }}>
                    Video
                </Typography>

                {isGeneratingVideo ? (
                    <CircularWithValueLabel></CircularWithValueLabel>
                ) : (
                    <VideoPlayer src={videoSrc} width={'100%'} controls={true} autoPlay={false} loop={false} />
                )}
            </Box>
        </Box>
    );
};

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Stack direction={'row'} sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box>
                <Typography variant="caption" component="div" sx={{ mt: '8px', mr: 2, color: 'text.secondary' }}>
                    Video is generating...
                </Typography>
            </Box>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        </Stack>
    );
}

export default function CircularWithValueLabel() {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 2));
        }, 2000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return <CircularProgressWithLabel value={progress} />;
}
