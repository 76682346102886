import React, { useEffect, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonGroup from '@mui/material/ButtonGroup';

// actions
import {
    articlesEditorSetHighlightMode,
    editorPartUpdate,
    setActiveArticle,
} from '../../../../../../actions/articles.actions';

// interfaces
import { Article } from "../../../../../../interfaces/article/Article.interface";
import { PlayerState } from '../../../../../../interfaces/PlayerState.interface';
import { Selector } from "../../../../../../interfaces/Selector.interface";

// handlers
import { AudioActionButtonPublish } from "./buttons/AudioActionButtonPublish";
import { AudioActionButtonGenerate } from "./buttons/AudioActionButtonGenerate";
import { AudioActionButtonTranslate } from "./buttons/AudioActionButtonTranslate";
import { AudioActionButtonHighlight } from "./buttons/AudioActionButtonHighlight";
import { AudioActionButtonLoadMP3 } from "./buttons/AudioActionButtonLoadMP3";
import { AudioActionButtonDownload } from "./buttons/AudioActionButtonDownload";
import { AudioActionButtonSave } from "./buttons/AudioActionButtonSave";
import { AudioActionButtonUnpublish } from "./buttons/AudioActionButtonUnpublish";
import { AudioActionButtonPublishAusha } from "./buttons/AudioActionButtonPublishAusha";
import { AudioActionButtonUnpublishAusha } from "./buttons/AudioActionButtonUnpublishAusha";


interface Props {
    setPlayerState: React.Dispatch<React.SetStateAction<PlayerState>>,
    setStreamEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const AudioActionButtons = ({ setPlayerState, setStreamEnabled }: Props) => {
    const dispatch = useDispatch();

    // state selector
    const article = useSelector((state: Selector) => state.article);
    const files = useSelector((state: Selector) => state.files);
    const aushaAuthorization = useSelector((state: Selector) => state.auth.aushaAuthorization);

    const { activeArticle, editor } = article;
    const { selectedLanguage } = article.editor;

    const [loadMP3, setLoadMP3] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const matches = useMediaQuery("(min-width:1200px)");

    const [isActiveArticleDirty, setIsActiveArticleDirty] = useState({
        isDirty: false,
        newStatus: '',
    });

    const [buttonsState, setButtonsState] = useState({
        showDownload: false,
        showGenerate: false,
        showLoadMp3: false,
        showPublish: false,
        showPublishAusha: false,
        showUnpublishAusha: false,
        showSave: true,
        showUnpublish: false,
        showHighlight: false,
        showTranslate: false,
    })

    const {
        showDownload,
        showGenerate,
        showLoadMp3,
        showPublish,
        showPublishAusha,
        showUnpublishAusha,
        showSave,
        showUnpublish,
        showHighlight,
        showTranslate
    } = buttonsState;

    // effect when active article is dirty
    useEffect(() => {
        if (isActiveArticleDirty.isDirty) {
            setIsActiveArticleDirty({
                isDirty: false,
                newStatus: ''
            });

            const innerDatas: Article[] = files
                .filter(file => file.fileName.replace(/\.xml$/i, '') === editor.articleName)
                .map(file => file);

            if (innerDatas.length <= 0) return;
            let innerData: Article = innerDatas.pop() as Article;

            const name = innerData.fileName.replace(/\.xml$/i, '');
            const status = isActiveArticleDirty.newStatus !== '' ? isActiveArticleDirty.newStatus : innerData.status;
            const article: Article = {
                ...innerData,
                name,
                status,
                introMusicEffect: { ...activeArticle.introMusicEffect },
                titleMusicEffect: { ...activeArticle.titleMusicEffect },
                outroMusicEffect: { ...activeArticle.outroMusicEffect },
                backgroundMusicEffect: { ...activeArticle.backgroundMusicEffect },
                language: activeArticle.language
            }

            dispatch(setActiveArticle(article));
            dispatch(editorPartUpdate(true)); // set 'isModified' to true
        }
        // eslint-disable-next-line
    }, [isActiveArticleDirty]);


    // effect to handle buttons visibility
    useEffect(() => {
        if (activeArticle.status !== 'PUBLISHED') {
            setButtonsState(state => ({
                ...state,
                showSave: true,
                showUnpublish: false,
                showPublish: (activeArticle.status === 'DRAFT'),
                showLoadMp3: (activeArticle.status === 'DRAFT'),
                showDownload: (activeArticle.status === 'DRAFT'),
                showPublishAusha: false,
                showUnpublishAusha: false
            }))
        } else {
            if ((activeArticle.audioMediaFile?.aushaPodcastId === undefined || activeArticle.audioMediaFile?.aushaPodcastId === null) && activeArticle.status === "PUBLISHED") {
                setButtonsState(state => ({
                    ...state,
                    showSave: false,
                    showPublishAusha: aushaAuthorization,
                    showUnpublishAusha: false,
                    showUnpublish: true,
                    showLoadMp3: true,
                    showPublish: false,
                    showDownload: true,
                }))
            }
            else {
                setButtonsState(state => ({
                    ...state,
                    showSave: false,
                    showPublishAusha: false,
                    showUnpublishAusha: true,
                    showUnpublish: true,
                    showLoadMp3: true,
                    showPublish: false,
                    showDownload: true,
                }))
            }

        }

        if (activeArticle.fileName !== '') {
            setButtonsState(state => ({
                ...state,
                showGenerate: true,
                showClose: true,
                showTranslate: true,
            }))

            dispatch(articlesEditorSetHighlightMode(false));

        } else {
            setButtonsState(state => ({
                ...state,
                showGenerate: false,
                showClose: false,
                showTranslate: false,
            }))
        }

        if (selectedLanguage === "fr-FR") {
            setButtonsState(state => ({
                ...state,
                showHighlight: true,
            }))
        } else {
            setButtonsState(state => ({
                ...state,
                showHighlight: false,
            }))
        }
        // eslint-disable-next-line
    }, [activeArticle.id, selectedLanguage, activeArticle.status, activeArticle.audioMediaFile?.aushaPodcastId]);


    return (
        <ButtonGroup
            data-test="audioactionbuttons-component"
            variant="outlined"
            orientation={`${matches ? `horizontal` : `vertical`}`}
        >
            {
                showUnpublish && <AudioActionButtonUnpublish />
            }
            {
                showPublishAusha && <AudioActionButtonPublishAusha />
            }
            {
                showUnpublishAusha && <AudioActionButtonUnpublishAusha />
            }

            {
                showSave && <AudioActionButtonSave
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    isGenerating={isGenerating}
                />
            }

            {
                showDownload && <AudioActionButtonDownload />
            }

            {
                showLoadMp3 && <AudioActionButtonLoadMP3
                    loadMP3={loadMP3}
                    setLoadMP3={setLoadMP3}
                    setStreamEnabled={setStreamEnabled}
                    setPlayerState={setPlayerState}
                />
            }

            {
                showHighlight && <AudioActionButtonHighlight />
            }

            {
                showTranslate && <AudioActionButtonTranslate loadMP3={loadMP3} />
            }

            {
                showGenerate && <AudioActionButtonGenerate
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    isGenerating={isGenerating}
                    setIsGenerating={setIsGenerating}
                />
            }

            {
                showPublish && <AudioActionButtonPublish />
            }

        </ButtonGroup>
    )
}
