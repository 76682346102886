interface Props {
    src: string;
    type?: string;
    width?: number | string;
    height?: number | string;
    controls?: boolean;
    autoPlay?: boolean;
    loop?: boolean;
    muted?: boolean;
}

export const VideoPlayer = ({
    src,
    type = 'video/mp4',
    width = '100%',
    height = 'auto',
    controls = true,
    autoPlay = false,
    loop = false,
    muted = false,
}: Props) => {
    return (
        <video
            id={'video-player'}    
            width={width}
            height={height}
            controls={controls}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            style={{
                borderRadius: 15,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            }}
        >
            <source id={'video-src'} src={src} type={type} />
            Your browser does not support the video tag.
        </video>
    );
};
