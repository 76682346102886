import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// components
import { VideoButtons } from '../videoButtons/VideoButtons';
import Swal from 'sweetalert2';

// mui
import { Box, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { VideoUpdateInfoBody } from '../../../../../interfaces/services/api.interfaces';

// actions
import { updateVideoInfo } from '../../../../../actions/video.actions';

export const VideoHeader = () => {
    const theme = useTheme();
    const video = useSelector((state: Selector) => state.video);
    const [videoName, setVideoName] = useState(video.fileName ? video.fileName : '');
    const [editMode, setEditMode] = useState(false);

    // effect to set the video name on the component when a video is selected
    useEffect(() => {
        if (video.id > 0) {
            setVideoName(video.fileName);
        }
    }, [video.id, video.fileName]);

    // handler to apply the new video name on the db when it has been edited
    const handleVideoNameChange = (name: string) => {
        const updatedVideo: VideoUpdateInfoBody = {
            id: video.id,
            name,
            languageCode: video.language.code,
            outroImageUrl: video.outroImageUrl,
            outroInfoEmail: video.outroInfoEmail,
            outroInfoName: video.outroInfoName,
            outroInfoPhone: video.outroInfoPhone,
        };

        updateVideoInfo(updatedVideo).then(res => {
            if (res.status === 500) {
                Swal.fire('Error', `An error occurred while renaming the video ${name}`, 'error');
            }
        });
    };

    return (
        <Box sx={{ p: 1, flexGrow: 1, width: '100%' }}>
            <Box width={'49.6%'}>
                <Stack direction="row" spacing={2}>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 4,
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            padding: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                        }}
                    >
                        <VideoButtons />
                    </Box>

                    {video.id > 0 && (
                        <Box
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: 4,
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                width: '70%',
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                {editMode ? (
                                    <TextField
                                        data-test="videoheader-input-name"
                                        id="videoheader-input-name"
                                        name="videoheader-input-name"
                                        label="Video name"
                                        variant="standard"
                                        value={videoName}
                                        onChange={e => setVideoName(e.target.value)}
                                        sx={{ minWidth: 350 }}
                                    />
                                ) : (
                                    <Typography variant="h6">{videoName}</Typography>
                                )}

                                <Tooltip title="Edit title">
                                    <IconButton
                                        aria-label="edit title"
                                        color="primary"
                                        onClick={() => {
                                            if (editMode) handleVideoNameChange(videoName);
                                            setEditMode(editMode => !editMode);
                                        }}
                                    >
                                        {editMode ? <DoneIcon /> : <EditIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};
