import { AnyAction } from 'redux';
import { types } from '../actions/actionTypes/actionTypes'
import { SocketState } from '../interfaces/Selector.interface';

const initialState: SocketState = {
    articleInfosUpdated: -1,
    newArticleAudioGeneration: -1,
    newArticleVideoGeneration: -1,
    videoScriptInfosUpdated: -1,
    newVideoScriptAudioGeneration: -1,
    newVideoScriptVideoGeneration: -1
}

export const socketReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case types.socketArticleInfosUpdated:
            return {
                ...state,
                articleInfosUpdated: action.payload.data as number
            } as SocketState

        case types.articleInfosUpToDate:
            return {
                ...state,
                articleInfosUpdated: -1
            } as SocketState

        case types.socketNewArticleAudioGenerated:
            return {
                ...state,
                newArticleAudioGeneration: action.payload.data as number
            } as SocketState

        case types.socketVideoScriptInfosUpdated:
            return {
                ...state,
                videoScriptInfosUpdated: action.payload.data as number
            } as SocketState

        case types.videoScriptInfosUpToDate:
            return {
                ...state,
                videoScriptInfosUpdated: -1
            } as SocketState

        case types.socketNewVideoScriptAudioGenerated:
            return {
                ...state,
                newVideoScriptAudioGeneration: action.payload.data as number
            } as SocketState

        case types.socketNewVideoScriptVideoGenerated:
            return {
                ...state,
                newVideoScriptVideoGeneration: action.payload.data as number
            } as SocketState
        default:
            return state as SocketState
    }
}