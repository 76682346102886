// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { TextField } from '@mui/material';

// interfaces
import { VideoKeyword } from '../../../../../../interfaces/video/VideoKeyword.interface';
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { VideoUpdateBody } from '../../../../../../interfaces/services/api.interfaces';

// actions
import { updateVideo } from '../../../../../../actions/video.actions';
import { generateVideoSaveBody } from '../../../../../../helpers/videos/generateVideoSaveBody';

interface Props {
    keyword: VideoKeyword;
    handleUpdateKeywordLine: (keyword: VideoKeyword, text: string) => void;
}

export const KeywordLine = ({ keyword, handleUpdateKeywordLine }: Props) => {
    const dispatch = useDispatch();
    const video = useSelector((state: Selector) => state.video);

    const handleSaveVideo = () => {
        const videoUpdateBody: VideoUpdateBody = generateVideoSaveBody(video);

        updateVideo(videoUpdateBody)
            .then(action => {
                dispatch(action);
                return 'ok';
            })
            .catch(e => {
                return 'error';
            });
    };

    return (
        <TextField
            id={`keyword-input-group-${keyword.group}-line-${keyword.line}`}
            key={`keyword-input-group-${keyword.group}-line-${keyword.line}`}
            label={`Line ${keyword.line}`}
            variant="standard"
            value={keyword.text}
            onChange={e => {
                handleUpdateKeywordLine(keyword, e.target.value);
            }}
            onBlur={handleSaveVideo}
        />
    );
};
