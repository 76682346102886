import { useState, useEffect } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// styled
import { TitleBox } from '../../styles/EditorPageStyle';

// mui
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CategoryIcon from '@mui/icons-material/Category';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import TagIcon from '@mui/icons-material/Tag';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { Article } from '../../../../../../interfaces/article/Article.interface';

// helpers
import { formatDateToString } from '../../../../../../helpers/formatDateToString';
import { setActiveArticle } from '../../../../../../actions/articles.actions';
import api from '../../../../../../helpers/services/api';

export const ArticleInfo = () => {
    const dispatch = useDispatch();

    // state selector
    const activeArticle = useSelector((state: Selector) => state.article.activeArticle);
    const articleParts = useSelector((state: Selector) => state.parts);

    const [characterCount, setCharacterCount] = useState(0);
    const [loadImage, setLoadImage] = useState(false);

    useEffect(() => {
        if (articleParts && articleParts.length > 0) {
            let count = 0;
            articleParts.forEach(p => {
                let array = p.content;
                if (array) {
                    const aloneTagO = /&lt;/g;
                    array = array.replace(aloneTagO, '<');
                    const aloneTagC = /&gt;/g;
                    array = array.replace(aloneTagC, '>');
                    const regexSpace = /&nbsp;/gi;
                    array = array.replace(regexSpace, ' ');
                    const regexEsper = /&amp;/gi;
                    array = array.replace(regexEsper, '&');
                    const regexTag = /<[^>]*>/gi;
                    array = array.replace(regexTag, '');
                    const break1 = /1.0s/gi;
                    array = array.replace(break1, '');
                    const break05 = /0.5s/gi;
                    array = array.replace(break05, '');

                    count += array.length;
                }
            });
            setCharacterCount(count);
        }
    }, [articleParts]);

    const updateArticleImageUrl = (imageUrl: string) => {
        dispatch(
            setActiveArticle({
                ...activeArticle,
                imageUrl,
            }),
        );
    };

    const setUrl = () => {
        setLoadImage(true);
        Swal.fire({
            title: `Current image`,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel',
            input: 'text',
            inputLabel: 'New image url:',
            inputPlaceholder: 'Enter the new image url',
            showCancelButton: true,
            html: "<img src='" + activeArticle.imageUrl + "' alt='No image defined yet' style='width:150px;'>",
        })
            .then(async answer => {
                const imageUrl = answer.value;

                if (answer.isConfirmed) {
                    let fileId = activeArticle.id ? activeArticle.id : -1;

                    // save article image
                    await api.article
                        .updateImage(fileId, imageUrl)
                        .then(async (resp: any) => {
                            const updatedArticle: Article = await resp.json();

                            if (
                                updatedArticle.imageUrl === undefined ||
                                updatedArticle.imageUrl === null ||
                                updatedArticle.imageUrl === ''
                            ) {
                                setLoadImage(false);
                                Swal.fire('Error', 'Error while uploading image', 'error');
                                return 'error';
                            } else {
                                updateArticleImageUrl(updatedArticle.imageUrl);
                                setLoadImage(false);
                                return 'ok';
                            }
                        })
                        .catch(e => {
                            Swal.fire('Error', e.message, 'error');
                            return 'error';
                        });
                }
            })
            .catch(err =>
                Swal.fire('Error', `An error occurred in the editor while renaming the file: ${err}`, 'error'),
            );
    };

    return (
        <TitleBox>
            <Stack direction="column" spacing={1.5} sx={{ pt: 2 }}>
                <Stack direction="row">
                    <Tooltip title="Article's ID">
                        <Stack direction="row">
                            <TagIcon fontSize="small" sx={{ mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-id" variant="body1">
                                {activeArticle.id > 0 ? activeArticle.id : '-'}
                            </Typography>
                        </Stack>
                    </Tooltip>

                    <Tooltip title="Article's name">
                        <Stack direction="row">
                            <NewspaperIcon fontSize="small" sx={{ ml: 1.5, mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-name" variant="body1">
                                {activeArticle.fileName ? `${activeArticle.fileName}` : <i>New article </i>}
                            </Typography>
                        </Stack>
                    </Tooltip>

                    <Tooltip title="Creation date">
                        <Stack direction="row">
                            <CalendarMonthIcon fontSize="small" sx={{ ml: 1.5, mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-date" variant="body1">
                                {formatDateToString(activeArticle.createdAt)}
                            </Typography>
                        </Stack>
                    </Tooltip>

                    <Tooltip title="Number of characters">
                        <Stack direction="row">
                            <BarChartIcon fontSize="small" sx={{ ml: 1.5, mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-charcount" variant="body1">
                                {characterCount}
                            </Typography>
                        </Stack>
                    </Tooltip>
                </Stack>

                <Stack direction="row" spacing={1}>
                    <Tooltip title="Topic">
                        <Stack direction="row">
                            <CategoryIcon fontSize="small" sx={{ mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-topic" variant="body1">
                                {activeArticle.topic ? (
                                    `${activeArticle.topic?.charAt(0)?.toUpperCase() + activeArticle.topic.slice(1)}`
                                ) : (
                                    <i>-</i>
                                )}
                            </Typography>
                        </Stack>
                    </Tooltip>

                    <Tooltip title="Tone">
                        <Stack direction="row">
                            <TheaterComedyIcon fontSize="small" sx={{ ml: 1.5, mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-tone" variant="body1">
                                {activeArticle?.tone ? `${activeArticle.tone.name}` : <i>-</i>}
                            </Typography>
                        </Stack>
                    </Tooltip>

                    <Tooltip title="Author">
                        <Stack direction="row">
                            <PersonIcon fontSize="small" sx={{ ml: 1.5, mr: 0.5 }} />
                            <Typography data-test="articleinfo-label-author" variant="body1">
                                {activeArticle?.author ? `${activeArticle.author}` : <i>-</i>}
                            </Typography>
                        </Stack>
                    </Tooltip>

                    <Tooltip title="Image">
                        <Box
                            width="70px"
                            height="30px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            sx={{ mt: 0.5, ml: -1, mr: 1, pb: 2 }}
                        >
                            {activeArticle.id &&
                                activeArticle.id > 0 &&
                                activeArticle.imageUrl !== undefined &&
                                activeArticle.imageUrl !== null &&
                                activeArticle.imageUrl !== '' && (
                                    <LoadingButton
                                        size="small"
                                        onClick={setUrl}
                                        variant={'outlined'}
                                        disabled={loadImage}
                                        loading={loadImage}
                                        sx={{
                                            cursor: 'pointer',
                                            maxHeight: '35px',
                                            maxWidth: '70px',
                                            minHeight: '35px',
                                            minWidth: '70px',
                                        }}
                                    >
                                        <img
                                            src={activeArticle.imageUrl}
                                            id="output"
                                            width="25px"
                                            height="25px"
                                            alt=""
                                            style={{
                                                borderRadius: '10%',
                                                cursor: 'pointer',
                                                objectFit: 'cover',
                                                marginLeft: '2px',
                                                marginRight: '8px',
                                            }}
                                        />
                                        <PhotoCameraIcon />
                                    </LoadingButton>
                                )}
                            {(!activeArticle.id ||
                                activeArticle.id <= 0 ||
                                activeArticle.imageUrl === undefined ||
                                activeArticle.imageUrl === null ||
                                activeArticle.imageUrl === '') && (
                                <LoadingButton
                                    size="small"
                                    onClick={setUrl}
                                    variant={'outlined'}
                                    disabled={loadImage}
                                    loading={loadImage}
                                    sx={{
                                        maxWidth: '30px',
                                        maxHeight: '30px',
                                        minWidth: '30px',
                                        minHeight: '30px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <PhotoCameraIcon />
                                </LoadingButton>
                            )}
                        </Box>
                    </Tooltip>
                </Stack>
            </Stack>
        </TitleBox>
    );
};
