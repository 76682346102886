// react
import React, { useState, useEffect } from 'react';

// redux
import { useSelector } from 'react-redux';

// mui
import { Box, Divider, useTheme, FormGroup, Typography } from '@mui/material';

// interfaces
import { Selector } from '../../../../../interfaces/Selector.interface';
import { LexiconItem } from './lexiconItem/LexiconItem';
import { Article } from '../../../../../interfaces/article/Article.interface';
import { DateFilterButtons } from '../audioFilesTab/dateFilterButtons/DateFilterButtons';

const NoFilesLabel = () => {
    const theme = useTheme();
    return (
        <Box sx={{ p: theme.spacing(1) }}>
            <Typography color={theme.palette.text.secondary} component="div" variant="caption">
                No files match the current filters
            </Typography>
        </Box>
    );
};

export const LexiconTab = React.memo(() => {
    const theme = useTheme();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const [draftArticlesList, setDraftArticlesList] = useState([] as Article[]);
    const [publishedArticlesList, setPublishedArticlesList] = useState([] as Article[]);

    useEffect(() => {
        const draftArticleList = files.filter(
            file => file.visible && (file.status === 'PENDING' || file.status === 'DRAFT'),
        );
        const publishedArticleList = files.filter(file => file.visible && file.status === 'PUBLISHED');

        setDraftArticlesList(draftArticleList);
        setPublishedArticlesList(publishedArticleList);
    }, [files]);

    return (
        <Box>
            <Box
                id="audio-tab-header"
                sx={{
                    backgroundColor: 'background.paper',
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: theme.spacing(2),
                    position: 'sticky',
                    pt: theme.spacing(1),
                    top: 73,
                    zIndex: 1,
                    width: '100%',
                }}
            >
                <DateFilterButtons />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <Typography data-test="lexicontab-label-unpublished" variant="h6">
                    Unpublished articles
                </Typography>
            </Box>

            <Divider sx={{ my: theme.spacing(1) }} />

            <FormGroup>
                {draftArticlesList.length === 0 && <NoFilesLabel key="no-draft-files-label" />}
                {draftArticlesList.map(item => (
                    <LexiconItem key={`lexicon-file-${item.id}`} item={item} showArticleName={false} />
                ))}
            </FormGroup>

            <Divider sx={{ my: theme.spacing(1) }} />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <Typography data-test="lexicontab-label-published" variant="h6">
                    Published articles
                </Typography>
            </Box>

            <Divider sx={{ my: theme.spacing(2) }} />

            <FormGroup>
                {publishedArticlesList.length === 0 && <NoFilesLabel key="no-published-files-label" />}
                {publishedArticlesList.map(item => (
                    <LexiconItem key={`lexicon-file-${item.id}`} item={item} showArticleName={false} />
                ))}
            </FormGroup>
        </Box>
    );
});
