import { types } from '../actions/actionTypes/actionTypes';

const initialState: any = [];

export const videoFilesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.videoFilesRead:
            return [
                ...action.payload.data,
            ]
        case types.videoFilesFilter:
            return [
                ...action.payload.data,
            ]
        case types.videoFilesSort:
            return [
                ...action.payload.data,
            ]
        case types.videoFilesUpdate:
            return [
                ...action.payload.data,
            ]
        case types.videoFilesClean:
            return initialState

        default:
            return state;
    }
}