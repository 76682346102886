import { Socket, io } from 'socket.io-client';

// store
import { store } from '../../store/store';

// actions
import {
    articleInfosUpdated,
    newArticleAudioGenerated,
    newVideoScriptAudioGenerated,
    newVideoScriptVideoGenerated,
    videoScriptInfosUpdated,
} from '../../actions/socket.actions';


const baseUrl: string | undefined = process.env.REACT_APP_API_URL;

const updateArticleInfos = (data: number) => {
    store.dispatch(articleInfosUpdated(data));
}

const updateArticleAudioGenerationStatus = (data: number) => {
    store.dispatch(newArticleAudioGenerated(data))
}

const updateVideoScriptInfos = (data: number) => {
    store.dispatch(videoScriptInfosUpdated(data));
}

const updateVideoScriptAudioGenerationStatus = (data: number) => {
    store.dispatch(newVideoScriptAudioGenerated(data))
}

const updateVideoGenerationStatus = (data: string) => {
    store.dispatch(newVideoScriptVideoGenerated(data))
}

export const createSocketClient = (): Socket | undefined => {
    if (baseUrl) {
        const token = localStorage.getItem('token');
        const socket: Socket = io(baseUrl, { auth: { token } });

        socket?.on('articleInfosUpdated', updateArticleInfos);
        socket?.on('articleAudioGenerated', updateArticleAudioGenerationStatus);
        socket?.on('videoScriptInfosUpdated', updateVideoScriptInfos);
        socket?.on('videoScriptAudioGenerated', updateVideoScriptAudioGenerationStatus);
        socket?.on('videoScriptVideoGenerated', updateVideoGenerationStatus);
        return socket;
    }
    return undefined;
};

export const updateSocketUser = (socket: Socket | undefined, token: string): Socket | undefined => {
    if (baseUrl) {
        socket?.disconnect();
        const newSocket = io(baseUrl, { auth: { token } });

        newSocket?.on('articleInfosUpdated', updateArticleInfos);
        newSocket?.on('articleAudioGenerated', updateArticleAudioGenerationStatus);
        newSocket?.on('videoScriptInfosUpdated', updateVideoScriptInfos);
        newSocket?.on('videoScriptAudioGenerated', updateVideoScriptAudioGenerationStatus);
        newSocket?.on('videoScriptVideoGenerated', updateVideoGenerationStatus);
        return newSocket;
    }
    return undefined;
};


export const destroySocketClient = (socket: Socket) => {
    socket.disconnect();
};
