// redux
import { useDispatch, useSelector } from 'react-redux';

// modules
import Swal from 'sweetalert2';

// mui
import { Box, Divider, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';

// actions & helpers
import { showPopupUploadAudio } from '../../../../../../actions/popup.action';
import { updateVideoInfo } from '../../../../../../actions/video.actions';

// interfaces
import { Video } from '../../../../../../interfaces/video/Video.interface';
import { Selector } from '../../../../../../interfaces/Selector.interface';

// helpers
import api from '../../../../../../helpers/services/api';

interface Props {
    item: Video;
}

export const SideBarVideoItemContextMenu = ({ item }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const files = useSelector((state: Selector) => state.files);

    const { fileName, id, status, language } = item;

    const handleDeleteAudio = () => {
        Swal.fire({
            title: 'Are you sure?',
            html: `Do you really want to delete audio script file from the video <b>${fileName}</b> ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        })
            .then(result => {
                if (result.isConfirmed) {
                    updateVideoInfo({ id, status: 'PENDING' }).then(() => {
                        Swal.fire('Success', `<b>${fileName}</b> audio was successfully deleted`, 'success');
                    });
                }
            })
            .catch(err => Swal.fire('Error', `An error occurred. The audio could not be deleted: ${err}`, 'error'));
    };

    const handleDeleteVideo = () => {
        Swal.fire({
            title: 'Are you sure?',
            html: `Do you really want to delete the video <b>${fileName}</b> ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        })
            .then(result => {
                if (result.isConfirmed) {
                    updateVideoInfo({ id, status: 'DELETED' }).then(() => {
                        Swal.fire('Success', `<b>${fileName}</b> file was successfully deleted`, 'success');
                    });
                }
            })
            .catch(err => Swal.fire('Error', `An error occurred. The video could not be deleted: ${err}`, 'error'));
    };

    // call this before showing SweetAlert:
    function fixBootstrapModal() {
        var focusedNodes = document.querySelectorAll('div[tabindex="-1"]');
        if (!focusedNodes) return;
        focusedNodes.forEach(elmnt => {
            elmnt.removeAttribute('tabindex');
            elmnt.classList.add('js-swal-fixed');
        });
    }

    // call this before hiding SweetAlert (inside done callback):
    function restoreBootstrapModal() {
        var previouslyFocusedNodes = document.querySelectorAll('.modal.js-swal-fixed');
        if (!previouslyFocusedNodes) return;
        previouslyFocusedNodes.forEach(elmnt => {
            elmnt.setAttribute('tabindex', '-1');
            elmnt.classList.remove('js-swal-fixed');
        });
    }

    const handleRenameVideo = () => {
        fixBootstrapModal();

        Swal.fire({
            title: `Rename the file "${fileName}"`,
            confirmButtonText: 'Rename',
            cancelButtonText: 'Cancel',
            input: 'text',
            inputLabel: 'New file name:',
            inputPlaceholder: 'Enter the new file name',
            showCancelButton: true,
            inputValidator: value => (!value ? 'New name field is required' : null),
        })
            .then(answer => {
                restoreBootstrapModal();
                const nameExists = files.find((file: any) => file.fileName.replace('.xml', '') === answer.value);

                if (answer.isConfirmed) {
                    if (!nameExists) {
                        const newFileName = answer.value;

                        updateVideoInfo({ id, name: newFileName }).then(res => {
                            if (res.status === 500) {
                                Swal.fire(
                                    'Error',
                                    `An error occurred while renaming the video ${newFileName}`,
                                    'error',
                                );
                            }
                        });
                    } else {
                        Swal.fire('Error', 'This file name is already used', 'error');
                    }
                }
            })
            .catch(err =>
                Swal.fire('Error', `An error occurred in the editor while renaming the video: ${err}`, 'error'),
            );
    };

    const handleUnpublishVideo = () => {
        Swal.fire({
            title: 'Are you sure?',
            html: `Do you really want to unpublish <b>${fileName}</b> ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
        })
            .then(result => {
                if (result.isConfirmed) {
                    updateVideoInfo({ id, status: 'DRAFT' });
                }
            })
            .catch(err =>
                Swal.fire('Error', `An error occurred in the editor while unpublishing the file: ${err}`, 'error'),
            );
    };

    const handleOpenPlayer = () => {
        api.client.getName().then(async (resp: any) => {
            const clientName: string = (await resp.json()).clientName;
            if (resp.ok) {
                const playerEnv = process.env.REACT_APP_ODIA_PLAYER_URL;
                const url = `${playerEnv}/${language.code}/${clientName}/${encodeURIComponent(fileName)}`;
                window.open(url);
            } else {
                throw new Error('Errors recovering client name');
            }
        });
    };

    const handleUploadAudio = () => {
        dispatch(showPopupUploadAudio());
    };

    const handleMenuAction = (action: string) => {
        switch (action) {
            case 'rename':
                handleRenameVideo();
                break;
            case 'deleteAudio':
                handleDeleteAudio();
                break;
            case 'deleteVideo':
                handleDeleteVideo();
                break;
            case 'unpublish':
                handleUnpublishVideo();
                break;
            case 'openPlayer':
                handleOpenPlayer();
                break;
            case 'uploadAudio':
                handleUploadAudio();
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Box px={2} py={0.5}>
                <Typography variant="body1" color={theme.palette.primary.main} component={'div'}>
                    <Stack direction="row" justifyContent="space-between" component={'div'}>
                        <Typography component={'span'}>{fileName}</Typography>
                        <Typography component={'span'} color={theme.palette.text.secondary}>{`#${id}`}</Typography>
                    </Stack>
                </Typography>
            </Box>

            <Divider sx={{ my: theme.spacing(0.5) }} />

            {status !== 'PUBLISHED' && (
                <MenuItem onClick={() => handleMenuAction('rename')}>
                    <EditIcon />
                    &nbsp; Rename file
                </MenuItem>
            )}

            {status === 'DRAFT' && (
                <MenuItem onClick={() => handleMenuAction('deleteAudio')}>
                    <MusicOffIcon />
                    &nbsp; Remove video
                </MenuItem>
            )}

            {status !== 'PUBLISHED' && (
                <MenuItem data-test="contextmenu-btn-delete" onClick={() => handleMenuAction('deleteVideo')}>
                    <DeleteIcon />
                    &nbsp; Delete file
                </MenuItem>
            )}

            {status === 'PUBLISHED' && (
                <MenuItem onClick={() => handleMenuAction('unpublish')}>
                    <CancelScheduleSendIcon />
                    &nbsp; Unpublish
                </MenuItem>
            )}

            {(status === 'DRAFT' || status === 'PUBLISHED') && (
                <MenuItem onClick={() => handleMenuAction('openPlayer')}>
                    <SmartDisplayIcon />
                    &nbsp; Open in Odia Player
                </MenuItem>
            )}

            {
                <MenuItem onClick={() => handleMenuAction('uploadAudio')}>
                    <UploadFileIcon />
                    &nbsp; Upload mp3 audio
                </MenuItem>
            }
        </Box>
    );
};
