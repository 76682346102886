import { AnyAction } from "redux";

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from "./actionCreator/actionCreator";

// interfaces
import { Video, VideoWithContent } from '../interfaces/video/Video.interface';
import { VideoImage } from '../interfaces/video/VideoImage.interface';
import { VideoKeyword } from '../interfaces/video/VideoKeyword.interface';
import { VideoPart } from '../interfaces/video/VideoPart.interface';
import api from "../helpers/services/api";
import { extendContentInformation } from "../helpers/generic/extendContent";
import { VideoToCreateBody, VideoUpdateBody, VideoUpdateInfoBody } from "../interfaces/services/api.interfaces";


export const setActiveVideo = (video: VideoWithContent): AnyAction => {
    return actionCreator(types.videoSetActive, video)
}

export const clearActiveVideo = (): AnyAction => {
    return actionCreator(types.videoClearActive)
}

// create a new empoty video on the database
export const createVideo = async (videoToCreate: VideoToCreateBody): Promise<AnyAction> => {
    try {
        const resp = await api.video.create(videoToCreate);
        if (resp.status === 201) {
            const createdVideo = await resp.json();
            const createdVideoExtended = extendContentInformation(createdVideo) as Video;
            return actionCreator(types.videoCreate, createdVideoExtended);
        } else {
            const errorMsg = 'Error in the createVideo action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the createVideo action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export const refreshVideoFiles = async (videoId: number, videoList: Video[]): Promise<AnyAction> => {
    try {
        const resp = await api.video.getVideoInfo(videoId);
        if (resp.status === 200) {
            const updatedVideo: Video = await resp.json();
            const updatedVideoExtended = extendContentInformation(updatedVideo) as Video;

            // Remove the old instance of the video if it already exists, then add the new instance
            const videoListToUpdate: Video[] = videoList.filter(video => video.id !== videoId);
            videoListToUpdate.push(updatedVideoExtended);

            return actionCreator(types.videoFilesUpdate, videoListToUpdate);
        } else {
            const errorMsg = 'Error in the refreshVideoInfo action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the refreshVideoInfo action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

// update the whole video on the state
export const updateVideo = async (body: VideoUpdateBody): Promise<AnyAction> => {
    try {
        const resp = await api.video.update(body);
        if (resp.status === 201) {
            const updatedVideo: VideoWithContent = await resp.json();
            return actionCreator(types.videoEdit, updatedVideo);
        } else {
            const errorMsg = 'Error in the updateVideo action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the updateVideo action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

// update the video info (without related entities) on the state
export const updateVideoInfo = async (body: VideoUpdateInfoBody): Promise<AnyAction> => {
    try {
        const resp = await api.video.updateInfo(body);
        if (resp.status === 201) {
            const updatedVideo: VideoWithContent = await resp.json();
            return actionCreator(types.videoEdit, updatedVideo);
        } else {
            const errorMsg = 'Error in the updateVideoInfo action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the updateVideoInfo action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export const updateVideoOutroInfoImage = async (videoId: number, imageString: string): Promise<AnyAction> => {
    try {
        const resp = await api.video.updateOutroInfoImage(videoId, imageString);
        if (resp.status === 200) {
            const updatedVideo: VideoWithContent = await resp.json();
            return actionCreator(types.videoEdit, updatedVideo);
        } else {
            const errorMsg = 'Error in the updateVideoOutroInfoImage action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the updateVideoOutroInfoImage action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export const deleteVideoOutroInfoImage = async (videoId: number): Promise<AnyAction> => {
    try {
        const resp = await api.video.deleteOutroInfoImage(videoId);
        if (resp.status === 200) {
            const updatedVideo: VideoWithContent = await resp.json();
            return actionCreator(types.videoEdit, updatedVideo);
        } else {
            const errorMsg = 'Error in the deleteVideoOutroInfoImage action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the deleteVideoOutroInfoImage action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

// update any video property on the state
export const updateVideoProperty = async (body: any): Promise<AnyAction> => {
    try {
        const resp = await api.video.update(body);
        if (resp.status === 201) {
            const updatedVideo: VideoWithContent = await resp.json();
            return actionCreator(types.videoEditProperty, updatedVideo);
        } else {
            const errorMsg = 'Error in the updateVideoProperty action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the updateVideoProperty action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

// update the video language
export const updateVideoLanguage = async (id: number, languageCode: string): Promise<AnyAction> => {
    try {
        const resp = await api.video.updateLanguage(id, languageCode);
        if (resp.status === 200) {
            const updatedVideo: VideoWithContent = await resp.json();
            return actionCreator(types.videoEdit, updatedVideo);
        } else {
            const errorMsg = 'Error in the updateVideoLanguage action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the updateVideoLanguage action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

// delete a video
export const deleteActiveVideo = async (videoId: number): Promise<AnyAction> => {
    try {
        const resp = await api.video.updateInfo({ id: videoId, status: 'DELETED' });
        if (resp.status === 201) {
            return actionCreator(types.videoClearActive);
        } else {
            const errorMsg = 'Error in the deleteVideo action';
            throw new Error(errorMsg);
        }
    } catch (error) {
        const errorMsg = `Error in the deleteVideo action: ${error}`;
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export const setVideoUrl = (url: string): AnyAction => {
    return actionCreator(types.videoSetUrl, url);
}

export const setVideoImages = (images: VideoImage[]): AnyAction => {
    return actionCreator(types.videoSetImages, images);
}

export const setKeywords = (keywords: VideoKeyword[]): AnyAction => {
    return actionCreator(types.videoSetKeywords, keywords);
}

export const setParts = (parts: VideoPart[]): AnyAction => {
    return actionCreator(types.videoSetParts, parts);
}

export const setGenerationStatus = (isGenerating: boolean): AnyAction => {
    return actionCreator(types.videoSetGenerationStatus, isGenerating);
}

export const setAudioScript = (videoParts: VideoPart[]): AnyAction => {
    return actionCreator(types.videoSetAudioScript, videoParts);
}

export const setVideoIsDownloadable = (isDownloadable: boolean): AnyAction => {
    return actionCreator(types.videoSetIsDownloadable, isDownloadable)
}

export const videoPartsUpdate = (parts: VideoPart[]): AnyAction => {
    return actionCreator(types.videoPartsUpdate, parts)
}