import { lightTheme } from './themes/lightTheme';
import { darkTheme } from './themes/darkTheme';

// Function to return the theme based on the theme name
export const appTheme = (themeName: string) => {
    switch (themeName) {
        case 'light':
            return lightTheme();
        case 'dark':
            return darkTheme();
        default:
            return lightTheme();
    }
};
