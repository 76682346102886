import { types } from "../actions/actionTypes/actionTypes";
import { VideoWithContent } from "../interfaces/video/Video.interface";
import { VideoPart, VideoSectionType } from "../interfaces/video/VideoPart.interface";
import { defaultVoice } from "../state/defaultVoice";

const defaultSoundEffect = {
    id: -1,
    defaultVolume: 0,
    url: "",
    volume: 0
}

const initialPart: VideoPart = {
    id: -1,
    selected: true,
    order: 0,
    voice: defaultVoice,
    content: '',
    subtitleText: '',
    contentHtml: '',
    contentHtmlOriginal: '',
    prosodyPitch: 0,
    prosodyRate: 0,
    prosodyVolume: 0,
    prosodyContour: null,
    pauses: [],
    replacementTexts: [],
    emotion: {
        emotionDegree: "",
        emotionStyle: ""
    },
    hasSyntaxError: false,
    visible: true,
    type: 'CONTENT' as VideoSectionType,
    language: {
        code: 'fr-FR',
        name: 'French',
        enabled: true,
        id: 1
    },
    ttsModel: null
}

const initialState: VideoWithContent = {
    audioMediaFile: null,
    backgroundMusicEffect: defaultSoundEffect,
    createdAt: Date.now(),
    fileName: '',
    generationLog: '',
    id: -1,
    introMusicEffect: defaultSoundEffect,
    isSelected: false,
    isGeneratingAudio: false,
    isGeneratingVideo: false,
    language: { code: 'fr-FR', name: 'French', enabled: true, id: 1 },
    lastModifiedBy: '',
    lastModifiedDate: '',
    name: '',
    outroImageUrl: '',
    outroInfoEmail: '',
    outroInfoName: '',
    outroInfoPhone: '',
    status: 'PENDING',
    updatedAt: Date.now(),
    userUploadedId: 1,
    videoMediaFile: null,
    visible: true,
    parts: [initialPart],
    images: [],
    keywords: []
}

export const videoReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.videoSetUrl:
            return {
                ...state,
                videoUrl: action.payload.data
            };
        case types.videoSetActive:
            return {
                ...action.payload.data
            };
        case types.videoSetImages:
            return {
                ...state,
                images: action.payload.data
            };
        case types.videoSetKeywords:
            return {
                ...state,
                keywords: action.payload.data
            };
        case types.videoCreate:
            return {
                ...state,
                ...action.payload.data
            };
        case types.videoEdit:
            return {
                ...state,
                ...action.payload.data
            };
        case types.videoEditProperty:
            return {
                ...state,
                ...action.payload.data
            };
        case types.videoSetGenerationStatus:
            return {
                ...state,
                isGenerating: action.payload.data
            };
        case types.videoSetAudioScript:
            return {
                ...state,
                parts: action.payload.data
            };
        case types.videoClearActive:
            return {
                ...initialState
            };
        default:
            return state
    }
}