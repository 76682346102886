// components
import { VideoIntroMusicSelector } from './videoIntroMusicSelector/VideoIntroMusicSelector';
import { VideoBackgroundMusicSelector } from './videoBackgroundMusicSelector/VideoBackgroundMusicSelector';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

export const SoundEffectsBox = () => {
    const theme = useTheme();
    return (
        <Box width="100%">
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 4,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    padding: 3,
                }}
            >
                <Typography variant="h6" color="primary">
                    Sound effects
                </Typography>

                <Stack direction="column" spacing={3} sx={{ my: 3 }}>
                    <VideoIntroMusicSelector />
                    <VideoBackgroundMusicSelector />
                </Stack>
            </Box>
        </Box>
    );
};
