import React from "react";

// modules
import { useDispatch, useSelector } from 'react-redux';

// components
import { ParentItemLabel } from "../audioFilesTab/audioFileItem/parentItemLabel/ParentItemLabel";

// mui
import { Box, Button, Stack } from "@mui/material";
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ListIcon from "@mui/icons-material/List";

// interfaces
import { PlaylistItem } from "./playlistItem/PlaylistItem";
import { Selector } from "../../../../../interfaces/Selector.interface";
import { showPopupNewPlaylist } from "../../../../../actions/popup.action";


export const PlaylistTab = React.memo(() => {
    const dispatch = useDispatch();

    const playlists = useSelector((state: Selector) => state.playlists.playlistList)

    const handleCreatePlaylist = () => {
        dispatch(showPopupNewPlaylist());
    }

    return (
        <>
            <TreeView
                data-test="playlisttab-tree-files"
                aria-label="playlist-tab"
                key="playlist-tab"
                expanded={["playlists"]}
                sx={{ mt: 1 }}
            >
                <TreeItem
                    data-test="playlisttab-label-playlists"
                    label={<ParentItemLabel Icon={ListIcon} text="PLAYLISTS" />}
                    nodeId="playlists"
                    key={`playlist-tab`}
                >
                    {Array.isArray(playlists) && playlists?.map(playlist =>
                    (
                        <TreeItem
                            key={`playlist-tab-box-${playlist.id}`}
                            nodeId={playlist.name}
                            label={
                                <Box key={`playlist-tab-item-${playlist.id}`} sx={{ my: 1 }}>
                                    <Stack
                                        direction='row'
                                        spacing={1}
                                        sx={{ alignItems: 'center', display: 'flex' }}
                                    >
                                        <PlaylistItem item={playlist} />
                                    </Stack>
                                </Box>
                            }
                        >
                        </TreeItem>
                    )
                    )}
                </TreeItem >
            </TreeView >

            <Button
                data-test="playlisttab-btn-new"
                disableElevation
                onClick={handleCreatePlaylist}
                sx={{ ml: "25%", mt: "10px" }}
                variant="contained"
            >
                New Playlist
            </Button>

        </>
    )
})
