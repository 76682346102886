import { useState, useEffect, useRef } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

// components
import { Player } from '../../../../../generics/player/Player';

// mui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    Typography,
} from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

// state
import { playerDefaultState } from '../../../../../../state/playerDefaultState';

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { BackgroundEffect } from '../../../../../../interfaces/generic/BackgroundEffect.interface';

// handlers
import { handleLoadAudio, handlePlayPause, handleVolumeChange } from '../../../../../generics/player/playerHandlers';
import { updateVideoProperty } from '../../../../../../actions/video.actions';

const StyledInput = styled(Input)`
    width: 50px;
`;

export const VideoBackgroundMusicSelector = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const video = useSelector((state: Selector) => state.video);
    const backgroundsList = useSelector((state: Selector) => state.soundEffects.backgroundMusicsList);

    const [selectedBackgroundId, setSelectedBackgroundId] = useState(0);
    const [playerState, setPlayerState] = useState({ ...playerDefaultState });
    const playerRef = useRef(null);

    const defaultVolume: number = 1;

    const backgroundVolumeParams = {
        step: 0.05,
        min: 0,
        max: 1,
        defaultVolume: video?.backgroundMusicEffect?.volume || defaultVolume,
    };

    const [backgroundVolume, setBackgroundVolume] = useState(backgroundVolumeParams.defaultVolume);

    const updateVideoBackgroundMusic = (backgroundMusicEffect: BackgroundEffect) => {
        updateVideoProperty({
            ...video,
            backgroundMusicEffect: { backgroundId: backgroundMusicEffect.id, volume: backgroundMusicEffect.volume },
        })
            .then(action => dispatch(action))
            .catch(e => Swal.fire('Error', e.message, 'error'));
    };

    const handleBackgroundChange = (e: any) => {
        if (e.target.value === '' || e.target.value === 0) {
            setSelectedBackgroundId(e.target.value);
            setBackgroundVolume(defaultVolume);

            const backgroundMusicEffect: BackgroundEffect = {
                id: 0,
                defaultVolume,
                volume: defaultVolume,
                url: '',
            };
            updateVideoBackgroundMusic(backgroundMusicEffect);
            handleLoadAudio(setPlayerState, '', false, backgroundVolume);
        } else {
            const selectedBackground = backgroundsList.filter((b: { id: any }) => b.id === e.target.value)[0];

            setBackgroundVolume(selectedBackground?.defaultVolume ? +selectedBackground?.defaultVolume : defaultVolume);
            setSelectedBackgroundId(e.target.value);

            const selectedBackgroundMusicEffect: BackgroundEffect = {
                id: selectedBackground.id,
                defaultVolume: selectedBackground.defaultVolume,
                volume: selectedBackground.defaultVolume,
                url: selectedBackground.url,
            };
            updateVideoBackgroundMusic(selectedBackgroundMusicEffect);
            handleLoadAudio(setPlayerState, selectedBackground?.url || '', false, backgroundVolume);
        }
    };

    const handleSliderChange = (e: any, newValue: any) => {
        setBackgroundVolume(+newValue);
    };

    const handleSliderChangeCommitted = (e: any, newValue: any) => {
        updateBackgroundVolume(+newValue);
    };

    const handleInputChange = (e: any) => {
        updateBackgroundVolume(+e.target.value);
    };

    const updateBackgroundVolume = (volume: number) => {
        let selectedBackground = backgroundsList.filter(b => b.id === selectedBackgroundId)[0];
        if (!selectedBackground) {
            const backgroundMusicEffect: BackgroundEffect = {
                id: 0,
                defaultVolume,
                volume: backgroundVolume,
                url: '',
            };
            updateVideoBackgroundMusic(backgroundMusicEffect);
        } else {
            setBackgroundVolume(volume);
            handleVolumeChange(setPlayerState, volume);

            const selectedBackgroundMusicEffect: BackgroundEffect = {
                id: selectedBackground.id,
                defaultVolume: selectedBackground.defaultVolume,
                volume,
                url: selectedBackground.url,
            };
            updateVideoBackgroundMusic(selectedBackgroundMusicEffect);
        }
    };

    const handleInputBlur = () => {
        if (backgroundVolume < 0) {
            setBackgroundVolume(0);
            handleVolumeChange(setPlayerState, 0);
        } else if (backgroundVolume > 1) {
            setBackgroundVolume(1);
            handleVolumeChange(setPlayerState, 1);
        }
    };

    useEffect(() => {
        const backgroundMusicId = video?.backgroundMusicEffect?.id || 0;
        const backgroundMusicVolume = video?.backgroundMusicEffect?.volume || defaultVolume;

        setSelectedBackgroundId(backgroundMusicId);
        setBackgroundVolume(backgroundMusicVolume);

        const selectedBackground = backgroundsList.filter(b => b.id === backgroundMusicId)[0];
        handleLoadAudio(setPlayerState, selectedBackground?.url || '', false, backgroundMusicVolume);

        // eslint-disable-next-line
    }, [video.id, backgroundsList]);

    return (
        <Box sx={{ textAlign: 'left', mb: 5 }}>
            <Player playerState={playerState} setPlayerState={setPlayerState} playerRef={playerRef} />

            <Stack direction="row">
                <FormControl fullWidth>
                    <InputLabel id="backgroundMusicSelectorLabel">Background Music</InputLabel>
                    <Select
                        id="backgroundMusicSelector"
                        key="backgroundMusicSelector"
                        data-test="background-music-selector"
                        label="Background Music"
                        labelId="backgroundMusicSelectorLabel"
                        onChange={handleBackgroundChange}
                        disabled={playerState.playing}
                        value={
                            backgroundsList.filter(b => b.id === selectedBackgroundId).length > 0
                                ? selectedBackgroundId
                                : ''
                        }
                    >
                        <MenuItem value={0} key={''}>
                            <em>None</em>
                        </MenuItem>
                        {backgroundsList &&
                            backgroundsList.length > 0 &&
                            backgroundsList.map(background => (
                                <MenuItem value={background.id} key={background.name}>
                                    {background.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <IconButton
                    aria-label="startPause"
                    onClick={() => handlePlayPause(setPlayerState)}
                    color="primary"
                    disabled={
                        (!playerState.playing && playerState.url === '') ||
                        (playerState.playing && playerState.isBuffering)
                    }
                    sx={{ fontSize: 32, marginTop: '5px' }}
                >
                    {playerState.url === '' &&
                    playerState.playing &&
                    (!playerState.duration || playerState.isBuffering) ? (
                        <CircularProgress color="primary" size={32} />
                    ) : playerState.playing ? (
                        <PauseCircleIcon fontSize="inherit" />
                    ) : (
                        <PlayCircleFilledWhiteIcon fontSize="inherit" />
                    )}
                </IconButton>
            </Stack>

            <Box sx={{ width: '100%', mt: 3 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography
                            id="background-music-slider"
                            component="div"
                            gutterBottom
                            color={theme.palette.text.secondary}
                            fontSize={'small'}
                            sx={{ textAlign: 'left' }}
                        >
                            Volume
                        </Typography>
                    </Grid>
                    <Grid item xs mr={1.5}>
                        <Slider
                            aria-label="backgroundMusicVolume"
                            aria-labelledby="background-music-slider"
                            value={backgroundVolume}
                            onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommitted}
                            size="small"
                            step={backgroundVolumeParams.step}
                            min={backgroundVolumeParams.min}
                            max={backgroundVolumeParams.max}
                            disabled={selectedBackgroundId === 0}
                        />
                    </Grid>
                    <Grid item>
                        <StyledInput
                            value={backgroundVolume}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            inputProps={{
                                step: backgroundVolumeParams.step,
                                min: backgroundVolumeParams.min,
                                max: backgroundVolumeParams.max,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            disabled={selectedBackgroundId === 0}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
