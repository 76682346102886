// modules
import { useSelector } from 'react-redux';

// components
import { AudioScriptBox } from './audioScriptBox/AudioScriptBox';
import { ImageUploadBox } from './imageUploadBox/ImageUploadBox';
import { KeywordsBox } from './keywordsBox/KeywordsBox';
import { OutroBox } from './outroBox/OutroBox';
import { VideoHeader } from './videoHeader/VideoHeader';
import { VideoPlayerBox } from './videoPlayer/VideoPlayerBox';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Stack } from '@mui/material';
import { SoundEffectsBox } from './soundEffectsBox/SoundEffectsBox';
import { Selector } from '../../../../interfaces/Selector.interface';

export const VideoEditorPage = () => {
    const theme = useTheme();
    const videoId = useSelector((state: Selector) => state.video.id);

    return (
        <Box sx={{ p: 3, width: '100%', backgroundColor: theme.palette.info.light }}>
            <CssBaseline />

            <VideoHeader />

            {videoId > 0 && (
                <Stack direction="row">
                    <Stack direction="column" spacing={2} sx={{ p: 1, width: '50%' }}>
                        <VideoPlayerBox />
                        <AudioScriptBox />
                        <SoundEffectsBox />
                    </Stack>

                    <Stack direction="column" spacing={2} sx={{ p: 1, width: '50%' }}>
                        <ImageUploadBox />
                        <KeywordsBox />
                        <OutroBox />
                    </Stack>
                </Stack>
            )}
        </Box>
    );
};
