import { AnyAction } from 'redux';

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from './actionCreator/actionCreator';


export const articleInfosUpdated = (articleId: number): AnyAction => {
    return actionCreator(types.socketArticleInfosUpdated, articleId)
}

export const articleInfosUpToDate = (): AnyAction => {
    return actionCreator(types.articleInfosUpToDate)
}

export const newArticleAudioGenerated = (articleId: number): AnyAction => {
    return actionCreator(types.socketNewArticleAudioGenerated, articleId)
}

export const videoScriptInfosUpdated = (videoId: number): AnyAction => {
    return actionCreator(types.socketVideoScriptInfosUpdated, videoId)
}

export const videoScriptInfosUpToDate = (): AnyAction => {
    return actionCreator(types.videoScriptInfosUpToDate)
}

export const newVideoScriptAudioGenerated = (videoScriptId: number): AnyAction => {
    return actionCreator(types.socketNewVideoScriptAudioGenerated, videoScriptId)
}

export const newVideoScriptVideoGenerated = (url: string): AnyAction => {
    return actionCreator(types.socketNewVideoScriptVideoGenerated, url)
}