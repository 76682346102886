import { VideoKeyword } from "../../interfaces/video/VideoKeyword.interface";

const DEFAULT_KEYWORD_GROUPS = 2;
const DEFAULT_KEYWORD_LINES = 3;

export const applyDefaultKeywordGroups = () => {
    const defaultKeywordGroups: VideoKeyword[] = [];

    for (let g = 0; g < DEFAULT_KEYWORD_GROUPS; g++) {
        for (let l = 0; l < DEFAULT_KEYWORD_LINES; l++) {
            defaultKeywordGroups.push({
                id: 0,
                group: g + 1,
                line: l + 1,
                text: '',
            });
        }
    }
    return defaultKeywordGroups;
};