
export const dateFilterToDays = (dateFilter: string): Date => {
    switch (dateFilter) {
        case 'today':
            return substractDaysToDate(0);
        case '3 days':
            return substractDaysToDate(3);
        case 'week':
            return substractDaysToDate(7);
        case 'month':
            return substractDaysToDate(30);
        case 'all':
            return substractDaysToDate(365);
        default:
            return substractDaysToDate(0);
    }
}

const substractDaysToDate = (daysToSubtract: number): Date => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - daysToSubtract);
    const dateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    return dateWithoutTime;
};