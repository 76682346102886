import { useEffect, useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Button, ButtonGroup, Stack } from '@mui/material';

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';
import { Article } from '../../../../../../interfaces/article/Article.interface';

// actions
import { sidebarSetDateFilter } from '../../../../../../actions/sidebar.actions';
import { searchFiles } from '../../../../../../actions/files.actions';
import { filterByCreationDate } from '../../../../../../helpers/generic/filterByCreationDate';
import { dateFilterToDays } from '../../../../../../helpers/dateFilterToDays';

export const DateFilterButtons = () => {
    const dispatch = useDispatch();

    // state selector
    const filterDateFilter = useSelector((state: Selector) => state.sidebar.dateFilter);
    const files = useSelector((state: Selector) => state.files);

    const [selectedFilter, setSelectedFilter] = useState(filterDateFilter as string);

    // default filter date: today
    const [dateFilter, setDateFilter] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    );

    // reset date filter
    useEffect(() => {
        setDateFilter(dateFilterToDays(filterDateFilter));
    }, [filterDateFilter]);

    useEffect(() => {
        dispatch(searchFiles(filterByCreationDate(files, dateFilter) as Article[]));
        // eslint-disable-next-line
    }, [dateFilter]);

    const handleSetDateFilter = (filter: string) => {
        dispatch(sidebarSetDateFilter(filter));
        setSelectedFilter(filter);
    };

    return (
        <Stack direction="row" spacing={0.7} sx={{ mb: '1em', display: 'flex', justifyContent: 'center' }}>
            <ButtonGroup>
                <Button
                    onClick={() => handleSetDateFilter('today')}
                    size="small"
                    sx={{ fontSize: '0.8em', textTransform: 'none', px: '5px' }}
                    variant={selectedFilter === 'today' ? 'contained' : 'outlined'}
                >
                    Today
                </Button>
                <Button
                    onClick={() => handleSetDateFilter('3 days')}
                    size="small"
                    sx={{ fontSize: '0.8em', textTransform: 'none', px: '5px' }}
                    variant={selectedFilter === '3 days' ? 'contained' : 'outlined'}
                >
                    3 days
                </Button>
                <Button
                    onClick={() => handleSetDateFilter('week')}
                    size="small"
                    sx={{ fontSize: '0.8em', textTransform: 'none', px: '5px' }}
                    variant={selectedFilter === 'week' ? 'contained' : 'outlined'}
                >
                    7 days
                </Button>
                <Button
                    onClick={() => handleSetDateFilter('month')}
                    size="small"
                    sx={{ fontSize: '0.8em', textTransform: 'none', px: '5px' }}
                    variant={selectedFilter === 'month' ? 'contained' : 'outlined'}
                >
                    30 days
                </Button>
                <Button
                    onClick={() => handleSetDateFilter('all')}
                    size="small"
                    sx={{ fontSize: '0.8em', textTransform: 'none', px: '5px' }}
                    variant={selectedFilter === 'all' ? 'contained' : 'outlined'}
                >
                    All
                </Button>
            </ButtonGroup>
        </Stack>
    );
};
